export class ReschedulingReport {
    private _id: string;
    public get id(): string {
        return this._id;
    }
    public set id(value: string) {
        this._id = value;
    }

    private _client: string;
    public get client(): string {
        return this._client;
    }
    public set client(value: string) {
        this._client = value;
    }
    private _isFinished: boolean = false;
    public get isFinished(): boolean {
        return this._isFinished;
    }
    public set isFinished(value: boolean) {
        this._isFinished = value;
    }

    private _mission: number;
    public get mission(): number {
        return this._mission;
    }
    public set mission(value: number) {
        this._mission = value;
    }

    private _commission: string;
    public get commission(): string {
        return this._commission;
    }
    public set commission(value: string) {
        this._commission = value;
    }


    private _serviceUnit: string;
    public get serviceUnit(): string {
        return this._serviceUnit;
    }
    public set serviceUnit(value: string) {
        this._serviceUnit = value;
    }

    private _serviceUnitCompany: string;
    public get serviceUnitCompany(): string {
        return this._serviceUnitCompany;
    }
    public set serviceUnitCompany(value: string) {
        this._serviceUnitCompany = value;
    }
    private _serviceUnitAddress: string;
    public get serviceUnitAddress(): string {
        return this._serviceUnitAddress;
    }
    public set serviceUnitAddress(value: string) {
        this._serviceUnitAddress = value;
    }

    private _date: string;
    public get date(): string {
        return this._date;
    }
    public set date(value: string) {
        this._date = value;
    }

    private _reasonNumber: string;
    public get reasonNumber(): string {
        return this._reasonNumber;
    }
    public set reasonNumber(value: string) {
        this._reasonNumber = value;
    }

    private _reasonText: string;
    public get reasonText(): string {
        return this._reasonText;
    }
    public set reasonText(value: string) {
        this._reasonText = value;
    }

    // private _reasonWeather: boolean = false;
    // public get reasonWeather(): boolean {
    //     return this._reasonWeather;
    // }
    // public set reasonWeather(value: boolean) {
    //     this._reasonWeather = value;
    // }

    // private _otherReason: boolean = false;
    // public get otherReason(): boolean {
    //     return this._otherReason;
    // }
    // public set otherReason(value: boolean) {
    //     this._otherReason = value;
    // }

    private _otherReasonText: string;
    public get otherReasonText(): string {
        return this._otherReasonText;
    }
    public set otherReasonText(value: string) {
        this._otherReasonText = value;
    }

    // private _additionalMission: boolean = false;
    // public get additionalMission(): boolean {
    //     return this._additionalMission;
    // }
    // public set additionalMission(value: boolean) {
    //     this._additionalMission = value;
    // }

    // private _constructionManager: boolean = false;
    // public get constructionManager(): boolean {
    //     return this._constructionManager;
    // }
    // public set constructionManager(value: boolean) {
    //     this._constructionManager = value;
    // }

    // private _clientNotAvailable: boolean = false;
    // public get clientNotAvailable(): boolean {
    //     return this._clientNotAvailable;
    // }
    // public set clientNotAvailable(value: boolean) {
    //     this._clientNotAvailable = value;
    // }

    // private _clientIllness: boolean = false;
    // public get clientIllness(): boolean {
    //     return this._clientIllness;
    // }
    // public set clientIllness(value: boolean) {
    //     this._clientIllness = value;
    // }

    // private _clientFixAppointment: boolean = false;
    // public get clientFixAppointment(): boolean {
    //     return this._clientFixAppointment;
    // }
    // public set clientFixAppointment(value: boolean) {
    //     this._clientFixAppointment = value;
    // }

    // private _cancelMission: boolean = false;
    // public get cancelMission(): boolean {
    //     return this._cancelMission;
    // }
    // public set cancelMission(value: boolean) {
    //     this._cancelMission = value;
    // }

    private _scheduledWeek: number;
    public get scheduledWeek(): number {
        return this._scheduledWeek;
    }
    public set scheduledWeek(value: number) {
        this._scheduledWeek = value
    }

    // private _additionalReasonIllness: boolean;
    // public get additionalReasonIllness(): boolean {
    //     return this._additionalReasonIllness
    // }
    // public set additionalReasonIllness(value: boolean) {
    //     this._additionalReasonIllness = value
    // }

    // private _additionalReasonTime: boolean;
    // public get additionalReasonTime(): boolean {
    //     return this._additionalReasonTime
    // }
    // public set additionalReasonTime(value: boolean) {
    //     this._additionalReasonTime = value
    // }

    // private _additionalReasonVehicle: boolean;
    // public get additionalReasonVehicle(): boolean {
    //     return this._additionalReasonVehicle
    // }
    // public set additionalReasonVehicle(value: boolean) {
    //     this._additionalReasonVehicle = value
    // }

    private _clientAgreedYes: boolean;
    public get clientAgreedYes(): boolean {
        return this._clientAgreedYes
    }
    public set clientAgreedYes(value: boolean) {
        this._clientAgreedYes = value
    }

    private _clientAgreedNo: boolean;
    public get clientAgreedNo(): boolean {
        return this._clientAgreedNo
    }
    public set clientAgreedNo(value: boolean) {
        this._clientAgreedNo = value
    }

    private _materialNotDelivered: boolean;
    public get materialNotDelivered(): boolean {
        return this._materialNotDelivered
    }
    public set materialNotDelivered(value: boolean) {
        this._materialNotDelivered = value
    }

    private _materialOnlyPartiallyDelivered: boolean;
    public get materialOnlyPartiallyDelivered(): boolean {
        return this._materialOnlyPartiallyDelivered
    }
    public set materialOnlyPartiallyDelivered(value: boolean) {
        this._materialOnlyPartiallyDelivered = value
    }

    private _comment: string;
    public get comment(): string {
        return this._comment;
    }
    public set comment(value: string) {
        this._comment = value;
    }

    private _created: Date;
    public get created(): Date {
        return this._created;
    }
    public set created(value: Date) {
        this._created = value;
    }
    
    constructor() {
        this._created = new Date();
    }
}