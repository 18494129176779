
import { Photo } from './photo';
import { AcceptanceReport } from './acceptanceReport';
import { RC2CertificateReport } from './rc2CertificateReport';
import { ConservatoryReport } from './conservatoryReport';
import { CheckReport } from './checkReport';
import { ServiceReport } from './serviceReport';
import { GlasReport } from './glasReport';
import { Exclude, Type } from 'class-transformer';
import { ActivityStatus } from './enum';
import { Message } from './message';
import { ReschedulingReport } from './reschedulingReport';
/*
 * Klasse für ein Datenobjekt einer Aktivität (Activity)
 * Datenobjekt
 */

export class Activity {
  private _id: number;
  public get id(): number {
    return this._id;
  }
  public set id(value: number) {
    this._id = value;
  }
  databaseId: number;
  private _missionId: number;
  public get missionId(): number {
    return this._missionId;
  }
  public set missionId(value: number) {
    this._missionId = value;
  }
  private _date: Date;
  public get date(): Date {
    return this._date;
  }
  public set date(value: Date) {
    this._date = value;
  }
  private _startTime: string;
  public get startTime(): string {
    return this._startTime;
  }
  public set startTime(value: string) {
    this._startTime = value;
  }
  private _endTime: string;
  public get endTime(): string {
    return this._endTime;
  }
  public set endTime(value: string) {
    this._endTime = value;
  }
  private _status: string;
  public get status(): string {
    return this._status;
  }
  public set status(value: string) {
    this._status = value;
  }
  private _statusInt: number;
  public get statusInt(): number {
    return this._statusInt;
  }
  public set statusInt(value: number) {
    this._statusInt = value;
  }
  private _closed: boolean;
  public get closed(): boolean {
    return this._closed;
  }
  public set closed(value: boolean) {
    this._closed = value;
  }
  private _serviceUnitId: number;
  public get serviceUnitId(): number {
    return this._serviceUnitId;
  }
  public set serviceUnitId(value: number) {
    this._serviceUnitId = value;
  }
  private _serviceUnitCompany: string;
  public get serviceUnitCompany(): string {
    return this._serviceUnitCompany;
  }
  public set serviceUnitCompany(value: string) {
    this._serviceUnitCompany = value;
  }
  private _serviceUnitAddress: string;
  public get serviceUnitAddress(): string {
    return this._serviceUnitAddress;
  }
  public set serviceUnitAddress(value: string) {
    this._serviceUnitAddress = value;
  }
  private _comment: string;
  public get comment(): string {
    return this._comment;
  }
  public set comment(value: string) {
    this._comment = value;
  }
  private _missionNumber: number;
  public get missionNumber(): number {
    return this._missionNumber;
  }
  public set missionNumber(value: number) {
    this._missionNumber = value;
  }

  private _name: string;
  public get name(): string {
    return this._name;
  }
  public set name(value: string) {
    this._name = value;
  }
  private _street: string;
  public get street(): string {
    return this._street;
  }
  public set street(value: string) {
    this._street = value;
  }
  private _zip: string;
  public get zip(): string {
    return this._zip;
  }
  public set zip(value: string) {
    this._zip = value;
  }
  private _city: string;
  public get city(): string {
    return this._city;
  }
  public set city(value: string) {
    this._city = value;
  }
  private _customerNumber: number;
  public get customerNumber(): number {
    return this._customerNumber;
  }
  public set customerNumber(value: number) {
    this._customerNumber = value;
  }
  private _latitude: number;
  public get latitude(): number {
    return this._latitude;
  }
  public set latitude(value: number) {
    this._latitude = value;
  }
  private _longitude: number;
  public get longitude(): number {
    return this._longitude;
  }
  public set longitude(value: number) {
    this._longitude = value;
  }
  private _commission: string;
  public get commission(): string {
    return this._commission;
  }
  public set commission(value: string) {
    this._commission = value;
  }
  private _commissionTel: string;
  public get commissionTel(): string {
    return this._commissionTel;
  }
  public set commissionTel(value: string) {
    this._commissionTel = value;
  }
  private _commissionTelText: string;
  public get commissionTelText(): string {
    return this._commissionTelText;
  }
  public set commissionTelText(value: string) {
    this._commissionTelText = value;
  }
  private _contactPerson: string;
  public get contactPerson(): string {
    return this._contactPerson;
  }
  public set contactPerson(value: string) {
    this._contactPerson = value;
  }
  private _numberReports: number;
  public set numberReports(value: number) {
    this._numberReports = value;
  }
  public get numberReports(): number {
    return this._numberReports;
  }
  @Type(() => Photo)
  @Exclude({ toPlainOnly: true })
  private _photos: Photo[];
  public get photos(): Photo[] {
    return this._photos;
  }
  @Type(() => Photo)
  public set photos(value: Photo[]) {
    this._photos = value;
  }

  private _numberPhotos: number;
  public set numberPhotos(value: number) {
    this._numberPhotos = value;
  }
  public get numberPhotos(): number {
    return this._numberPhotos;
  }
  @Type(() => ReschedulingReport)
  private _reschedulingReport: ReschedulingReport;
  public get reschedulingReport(): ReschedulingReport {
    return this._reschedulingReport;
  }
  @Type(() => ReschedulingReport)
  public set reschedulingReport(value: ReschedulingReport) {
    this._reschedulingReport = value;
  }
  @Type(() => ConservatoryReport)
  private _conservatoryReport: ConservatoryReport;
  public get conservatoryReport(): ConservatoryReport {
    return this._conservatoryReport;
  }
  @Type(() => ConservatoryReport)
  public set conservatoryReport(value: ConservatoryReport) {
    this._conservatoryReport = value;
  }
  @Type(() => CheckReport)
  private _checkReport: CheckReport;
  public get checkReport(): CheckReport {
    return this._checkReport;
  }
  @Type(() => CheckReport)
  public set checkReport(value: CheckReport) {
    this._checkReport = value;
  }
  @Type(() => AcceptanceReport)
  private _acceptanceReport: AcceptanceReport;
  public get acceptanceReport(): AcceptanceReport {
    return this._acceptanceReport;
  }
  @Type(() => AcceptanceReport)
  public set acceptanceReport(value: AcceptanceReport) {
    this._acceptanceReport = value;
  }
  private _hasrc2certificateReport: string;
  public get hasrc2certificateReport(): string {
    return this._hasrc2certificateReport;
  }
  public set hasrc2certificateReport(value: string) {
    this._hasrc2certificateReport = value;
  }
  @Type(() => RC2CertificateReport)
  private _rc2certificateReport: RC2CertificateReport;
  public get rc2certificateReport(): RC2CertificateReport {
    return this._rc2certificateReport;
  }
  @Type(() => AcceptanceReport)
  public set rc2certificateReport(value: RC2CertificateReport) {
    this._rc2certificateReport = value;
  }
  @Type(() => ServiceReport)
  private _serviceReport: ServiceReport;
  public get serviceReport(): ServiceReport {
    return this._serviceReport;
  }
  @Type(() => ServiceReport)
  public set serviceReport(value: ServiceReport) {
    this._serviceReport = value;
  }
  @Type(() => GlasReport)
  private _glasReportList: GlasReport[];
  public get glasReportList(): GlasReport[] {
    return this._glasReportList;
  }
  @Type(() => GlasReport)
  public set glasReportList(value: GlasReport[]) {
    this._glasReportList = value;
  }

  @Type(() => Message)
  private _messageList: Message[];
  public get messageList(): Message[] {
    return this._messageList;
  }
  @Type(() => Message)
  public set messageList(value: Message[]) {
    this._messageList = value;
  }

  private _sending: boolean;
  public get sending(): boolean {
    return this._sending;
  }
  public set sending(value: boolean) {
    this._sending = value;
  }

  private _lastSendTime: Date;
  public get lastSendTime(): Date {
    return this._lastSendTime;
  }
  public set lastSendTime(value: Date) {
    this._lastSendTime = value;
  }

  private _timestampSaveReports: Date
  public get timestampSaveReports() : Date {
    return this._timestampSaveReports
  }
  public set timestampSaveReports(value: Date) {
    this._timestampSaveReports = value
  }

  private _activityCompletelySended: Date
  public get activityCompletelySended(): Date {
    return this._activityCompletelySended
  }
  public set activityCompletelySended(value: Date) {
    this._activityCompletelySended = value
  }

  private _serviceUnit: string
  public get serviceUnit(): string {
    return this._serviceUnit
  }
  public set serviceUnit(value: string) {
    this._serviceUnit = value
  }

  public get address() :string {
    return this.name + "\n " + this.street + "\n " + this.zip + ", " + this.city;
  }
  /*
   * Konstruktor der Klasse
   */
  constructor() {
    this.sending = false;
    this.numberReports = 0;
    this.numberPhotos = 0;
  }

  public compare(compareActivity: Activity): boolean {
    let compareResult: boolean = true;

    if (this.id != compareActivity.id) {
      return false;
    }

    if (this.missionId != compareActivity.missionId) {
      return false;
    }

    if (this.date != compareActivity.date) {
      return false;
    }

    if (this.startTime != compareActivity.startTime) {
      return false;
    }

    if (this.endTime != compareActivity.endTime) {
      return false;
    }

    // nur status bis transfer kann zuverlässig verglichen werden, danach klaffen die statusnummern auf
    // dem Server und in der App aus einander
    if([ActivityStatus.new,ActivityStatus.approved,ActivityStatus.transfer].includes(compareActivity.statusInt)){
      if (this.status != compareActivity.status) {
        return false;
      }
      
      if (this.statusInt != compareActivity.statusInt) {
        return false;
      }
    }
   
    if (this.closed != compareActivity.closed) {
      return false;
    }
    if (this.serviceUnitId != compareActivity.serviceUnitId) {
      return false;
    }
    if (this.serviceUnitCompany != compareActivity.serviceUnitCompany) {
      return false;
    }
    if (this.serviceUnitAddress != compareActivity.serviceUnitAddress) {
      return false;
    }
    // if (this.comment != compareActivity.comment) {
    //   return false;
    // }
    if (this.missionNumber != compareActivity.missionNumber) {
      return false;
    }
    if (this.name != compareActivity.name) {
      return false;
    }
    if (this.street != compareActivity.street) {
      return false;
    }
    if (this.zip != compareActivity.zip) {
      return false;
    }
    if (this.city != compareActivity.city) {
      return false;
    }
    if (this.customerNumber != compareActivity.customerNumber) {
      return false;
    }
    if (this.latitude != compareActivity.latitude) {
      return false;
    }
    if (this.longitude != compareActivity.longitude) {
      return false;
    }
    if (this.commission != compareActivity.commission) {
      return false;
    }
    if (this.commissionTel != compareActivity.commissionTel) {
      return false;
    }
    if (this.commissionTelText != compareActivity.commissionTelText) {
      return false;
    }
    if (this.contactPerson != compareActivity.contactPerson) {
      return false;
    }
    if (this.hasrc2certificateReport != compareActivity.hasrc2certificateReport) {
      return false;
    }
    /*if (this.sending != compareActivity.sending) {
      return false;
    }*/
    
    return compareResult;
  }

}
